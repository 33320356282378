import { atom } from "recoil";

export declare type Room = {
  url: string;
} | null

export const currentRoomState = atom<Room>({
  key: 'currentRoomState',
  default: null
})

export declare type Option = {
  open: boolean;
  type: "report" | "default" | "reportSuccess",
  id: string;
  userId: string;
  isReply: boolean;
  firstCommentId: string;
} | null

export const optionState = atom<Option>({
  key: "optionState",
  default: null
})