import React, {Dispatch, SetStateAction} from "react";
import styled from "styled-components";
import {KebabMenu} from "../../../common";
import dayjs from 'dayjs';
import {useRecoilState} from "recoil";
import {optionState} from "../../stores/room";
import {ReplyData} from "../../containers/LaunchTask/LaunchTask";


export declare type Reply = {
  created_at: string
  created_by: string
  description: string
  location: string
  room: string
  updated_at: string
  updated_by: string
  uuid: string
  nickname?: string | null | undefined
  like: number;
  dislike: number;
  comment_like: 1 | -1 | 0;
}

export const Reply = ({
                        data,
                        setInput,
                        setReplyData,
                        firstCommentId,
                      }: { data: Reply, setInput: Dispatch<SetStateAction<string>>, setReplyData: Dispatch<SetStateAction<ReplyData | null>>, firstCommentId: string }) => {
  const [option, setOption] = useRecoilState(optionState);

  const date = React.useMemo(() => {
    const UTCDate = new Date(data.created_at);

    return dayjs(UTCDate).format('YYYY.MM.DD HH:mm:ss')
  }, []);

  const userName = React.useMemo(() => {
    return data.nickname?.slice(0, 11)
  }, []);


  const handleOpenOption = () => {
    setOption({
      open: true,
      type: "default",
      id: data.uuid,
      userId: data.created_by,
      isReply: true,
      firstCommentId: firstCommentId,
    })
  }

  const handleReply = async () => {

    // 답글달기 input
    setReplyData({
      commentId: firstCommentId,
      mentionId: data.created_by,
    });

    // mention name 은 백엔드로는 보내지 않음
    const mentionName = data.nickname?.slice(0, 11) || userName?.slice(0, 11)
    setInput(`@${mentionName} : `);
  }

  return (
    <Wrapper>

      <Divider>
        ㄴ
      </Divider>
      <Content>
        <Header>
          {/* header */}
          <Info>
            <Name>
              {data.nickname?.slice(0, 11) || userName?.slice(0, 11)}
            </Name>
            <DateStyle>
              {date}
            </DateStyle>
          </Info>
          <MenuWrapper>
            <KebabMenu onClick={handleOpenOption} width={10} height={10}/>
          </MenuWrapper>
        </Header>
        <Body>
          {/* body */}
          {data.description}
        </Body>
        <Footer>
          {/* footer */}
          <ReplyWrapper onClick={handleReply}>
            답글
          </ReplyWrapper>
        </Footer>
      </Content>
    </Wrapper>
  )
}

export default Reply;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Divider = styled.div`
  width: 4%;
  padding-top: 12px;
  color: #888888;
`;

const Content = styled.div`
  width: 96%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Body = styled.div`
  width: 100%;
  word-break: break-all;
  font-size: 14px;
  color: black;
  white-space: pre-line;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
`;

const MenuWrapper = styled.div`
  cursor: pointer;
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const Name = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: black;
`;

const DateStyle = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #767171;
`;


const ReplyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0px;
  font-size: 14px;
  color: #737373;
  cursor: pointer;
`;
