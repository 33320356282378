import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import React from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { syncHeader } from "../api";
import { getUserGoogleUrl, postUserGoogle } from "../api/login";
import { Google } from "../images/components/icons";
import Datguri from '../images/logo/Datguri.png';

const extension_id = process.env.REACT_APP_EXTENSION_ID;
export const LoginPage = () => {
  // const [searchParams] = useSearchParams();
  const navigate = useNavigate()
  const [firstLoad, setFirstLoad] = React.useState(true)
  const [cookies, setCookies] = useCookies(["access_token"]);

  React.useEffect(() => {

    const getParameterByName = (
      name: string,
      url = window.location.href
    ) => {
      name = name.replace(/\[]]/g, "\\$&");
      let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return results[2].replace(/\+/g, " ");
    };

    const code =  getParameterByName('code')
    if (code && code.length > 0 && firstLoad) {
      handleGoogleSuccess(code)
      setFirstLoad(false)
    }
  }, [])


  const handleGoogleSuccess = async (credentialResponse: string) => { 
    const res = await postUserGoogle({ authCode: credentialResponse })
    if (res?.status === 201) {
      if (!res.data['access_token']) return ; 
      syncHeader({
        key: 'Authorization',
        value: "Bearer " + res.data['access_token']
      })
      setCookies("access_token", res.data['access_token']);
      navigate(res.data.is_newbie ? '/login/info' : '/login/success')
      window.chrome.runtime.sendMessage(extension_id,
        { type: 'login', token: res.data['access_token'] },
        (res2) => { if (res2.status === 'Success') navigate(res.data.is_newbie ? '/login/info' : '/login/success') }
      );
    } 
  }

  const handleGoogleLogin = async () => {
    const res = await getUserGoogleUrl();

    if (res.status === 200) {
      window.location.assign(res.data.msg)
    }
  }

  const test = () => {

  }

  return (
    <Wrapper>
      <Logo src={Datguri}/>
      <Title>댓글이</Title>
      <Desc>회원가입 및 로그인을 완료해주세요.</Desc>
      {/* <GoogleLoginButton onClick={handleGoogleLogin}>구글 로그인</GoogleLoginButton> */}
      <GoogleLoginButton onClick={handleGoogleLogin}>
        <Google />
        Continue with Google
      </GoogleLoginButton>
      {/* <GoogleLogin
        useOneTap
        theme="filled_blue"
        onSuccess={test}
        // onError={handleGoogleFailure}
        width={'600px'}
      /> */}
    </Wrapper>
  )
}

export default LoginPage;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  height: 150px;

`

const Title = styled.h1`
  font-size: 36px;
  font-weight: 900;
`;

const Desc = styled.p`
  font-size: 24px;
  font-weight: 500;
  text-align: center;
`;

const GoogleLoginButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  height: 40px;
  width: 280px;
  padding: 8.5px 14px;
  transform: scale(1);
  transition: 0.3s;
  background-color: white;
  color: gray;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 15%) 0px 0px 6px;
  &:hover {
    transform: scale(1.02);
    box-shadow: rgb(0 0 0 / 25%) 0px 0px 10px;
  }
  &:disabled {
    &:hover {
      transform: none;
      box-shadow: none;
    }
    cursor: default;
    background-color: white;
  }
`