import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { postUserNickname } from "../api/user";
import Datguri from '../images/logo/Datguri.png';

const isEmtpy = (str: string) => {
  return str.trim().length === 0;
}
export const LoginInfoPage = () => {

  const navigate = useNavigate();
  const [name, setName] = React.useState("");

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }

  const handleNext = async () => {
    const res = await postUserNickname(name)
    if (res.status === 201) {
      navigate('/login/success');
    }
  }

  return (
    <Wrapper>
        <Logo src={Datguri}/>
        <Title>댓글이</Title>
        <Desc>사용하실 프로필 이름을 정해주세요<br/>(추후 변경 가능합니다)</Desc>
        <InputWrapper>
          <Input placeholder="User Name" value={name} onChange={handleChangeName}/>
        </InputWrapper>
        <Button disabled={isEmtpy(name)} onClick={handleNext}>다음으로</Button>
    </Wrapper>
  )
}

export default LoginInfoPage;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  height: 150px;

`

const Title = styled.h1`
  font-size: 36px;
  font-weight: 900;
`;

const Desc = styled.p`
  font-size: 24px;
  font-weight: 500;
  text-align: center;
`;


const Input = styled.input`
  color: currentcolor;
  border: 0px;
  box-sizing: content-box;
  background: none;
  height: 1.5em;
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0px;
  width: 100%;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  &:focus {
    outline: none;
  }
`;

const InputWrapper = styled.div`
  width: 300px;
  padding: 8.5px 14px;
  border-bottom: 1px solid #000;
  margin-bottom: 40px;
`;


const Button = styled.button`
  height: 20px;
  width: 180px;
  padding: 8.5px 14px;
  transition: 0.3s;
  background-color: black;
  border-radius: 20px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    transform: scale(1.02);
    box-shadow: rgb(0 0 0 / 25%) 0px 0px 10px;
  }
  &:disabled {
    &:hover {
      transform: none;
      box-shadow: none;
    }
    cursor: default;
    background-color: gray;
  }
`;