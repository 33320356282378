import { apiInstance } from ".";


export const getUserGoogleUrl = async () => {
  const url = '/user/google/url';
  return apiInstance.get(url)
  .then(res => res)
  .catch(err => err.response || err);
}

export const postUserGoogle = async ({ authCode }: { authCode: string }) => {
  const url = '/user/google';
  return apiInstance.post(url, {
    "auth_code": authCode
  })
  .then(res => res)
  .catch(err => err.response || err);
}

