import { apiInstance } from "../../api"

export declare type PostReportProps = {
  comment_uuid: string
  description: string
}
export const postReport = async ({
  comment_uuid,
  description
}: PostReportProps) => {
  const url = '/report'
  return apiInstance.post(url, {
    comment_uuid,
    description
  })
  .then(res => res)
  .catch(err => err.response || err);
}