import {apiInstance} from "../../api";
import {ReplyData} from "../../content/containers/LaunchTask/LaunchTask";

declare type postCommentProps = {
  location: string;
  comment: string;
}


export const postComment = async ({
                                    location,
                                    comment,
                                  }: postCommentProps) => {
  const url = '/comment'

  return apiInstance.post(url, {
    location: location,
    description: comment
  })
    .then((res) => res)
    .catch(err => err.response || err)
}

declare type replyCommentProps = {
  location: string;
  comment: string;
  replyData: ReplyData
}

export const replyComment = async ({
                                     location,
                                     comment,
                                     replyData
                                   }: replyCommentProps) => {
  console.log(replyData)
  const url = '/reply'

  return apiInstance.post(url, {
    comment_uuid: replyData.commentId,
    mention_uuid: replyData.mentionId,
    description: comment
  })
    .then((res) => res)
    .catch(err => err.response || err)
}

export const getReply = async (commentId: string) => {
  const url = `/reply/comment/${commentId}`

  return apiInstance.get(url)
    .then((res) => res)
    .catch(err => err.response || err)

}

export const getCommentLike_id = async (comment_uuid: string) => {
  const url = `/comment/like/${comment_uuid}`

  return apiInstance.get(url)
    .then(res => res)
    .catch(err => err.response || err)

}

export const postCommentLike = async ({
                                        comment_uuid,
                                        like
                                      }: { comment_uuid: string, like: -1 | 0 | 1 }) => {
  const url = `/comment/like`;
  return apiInstance.post(url, {
    comment_uuid,
    like
  })
    .then(res => res)
    .catch(err => err.response || err);
}

export const deleteComment_id = async (comment_uuid: string) => {
  const url = `/comment/${comment_uuid}`;
  return apiInstance.delete(url)
    .then(res => res)
    .catch(err => err.response || err);
}

export const deleteReply_id = async (reply_uuid: string) => {
  const url = `/reply/${reply_uuid}`;
  return apiInstance.delete(url)
    .then(res => res)
    .catch(err => err.response || err);
}