import React from "react";
import { useCookies } from "react-cookie";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { syncHeader } from "../api";

const login_url = process.env.REACT_APP_LOGIN_PATH || ""

const LoginFramework = () => {

  const [cookies, setCookies] = useCookies(["access_token"]);
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();
  React.useEffect(() => {
    if (cookies.access_token) {
      syncHeader({
        key: 'Authorization',
        value: 'Bearer ' + cookies.access_token
      });
    } else {
    }
    setLoading(false);
  }, []);

  return (
    <>
      { loading &&
        <></>
      }
      { !loading &&
        <Outlet />
      }
    </>
  )
}

export default LoginFramework;