import React from "react";
import styled from "styled-components";
import Datguri from '../images/logo/Datguri.png';

export const InfoSuccessPage = () => {

  // TODO: 여기서 localstorage comment_launch_cache true로 변경

  React.useEffect(() => {
    // localStorage.setItem('comment_launch_cache', "true");
    if (chrome.storage) {
      chrome.storage.sync.set({
        "comment_launch_cache": true
      });
    }
  }, []);

  return (
    <Wrapper>
        <Logo src={Datguri}/>
        <Title>댓글이</Title>
        <Desc>닉네임을 변경했습니다.</Desc>
        <div style={{height: '60px' }}/>
    </Wrapper>
  )
}

export default InfoSuccessPage;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  height: 150px;

`

const Title = styled.h1`
  font-size: 36px;
  font-weight: 900;
`;

const Desc = styled.p`
  font-size: 24px;
  font-weight: 500;
  text-align: center;
`;