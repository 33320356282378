import { atom } from "recoil";

export declare type User = {
  access_token: string;
  created_at: string; 
  email: string;
  name: string;
  nickname: string;
  oauth_id: string;
  uuid: string;
}
export const userState = atom<User | null>({
  key: 'userState',
  default: null,
})

export const authCodeState = atom({
  key: 'authCodeState',
  default: '',
})