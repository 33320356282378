import React from "react";
import {useRecoilState, useSetRecoilState} from "recoil";
import styled, {css} from "styled-components";
import {Refresh, Send} from "../../../common";
import {deleteComment_id, deleteReply_id, postComment, replyComment} from "../../../common/apis/comment";
import {postReport} from "../../../common/apis/report";
import {postCommentBestUrl, postCommentUrl} from "../../../common/apis/room";
import Comment from "../../components/Comment";
import {currentRoomState, optionState} from "../../stores/room";
import {userState} from "../../stores/user";
import {useSearchParams} from "react-router-dom";
import {getUserInfo} from "../../../common/apis/user";
import {syncHeader} from "../../../api";
import {refreshReplyState} from "../../stores/reply";

const LOGIN_PATH = process.env.REACT_APP_LOGIN_PATH || "";
const extension_id = process.env.REACT_APP_EXTENSION_ID;

// 답글 작성 및 처리를 할 때 사용된다.
export type ReplyData = {
  commentId: string,
  mentionId: string,
}


export declare type Option = "Like" | "Recent"
export const LaunchTask = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [user, setUser] = useRecoilState(userState);
  const [currentRoom, setCurrentRoom] = useRecoilState(currentRoomState)
  const [currentCommentList, setCurrentCommentList] = React.useState<Comment[]>([]);
  const [currentWritingComment, setCurrentWritingComment] = React.useState("")
  const [replyData, setReplyData] = React.useState<ReplyData | null>(null);
  const [refreshReply, setRefreshReply] = useRecoilState(refreshReplyState);
  const [currentOption, setCurrentOption] = React.useState<Option>("Like")

  const [commentHeight, setCommentHeight] = React.useState(20);
  const [loading, setLoading] = React.useState(false);
  const [isMore, setIsMore] = React.useState(true);


  const offset = React.useRef(0);
  const limit = 10;


  const handleCommentHeight = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCommentHeight(e.target.scrollHeight > 100 ? 100 : e.target.scrollHeight - 5);
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key !== 'Enter') return;
    if (e.shiftKey || e.altKey || e.ctrlKey || e.metaKey) {
      return;
    }

    e.stopPropagation();
    e.preventDefault();
    handleSendMessage();
    // inputRef.current.focus
  }

  const handleSendMessage = async () => {
    if (currentWritingComment.length > 0) {

      // 답글일 경우
      if (replyData) {
        const res = await replyComment({
          location: currentRoom?.url || window.location.href,
          comment: currentWritingComment.split(" : ")[1].trim(),
          replyData: replyData
        })

        if (res?.status === 201) {
          setCurrentWritingComment("");
          // handleRefreshComment(true);
          setCommentHeight(20);
          // setCurrentOption('Recent')
          setIsMore(true)
          setRefreshReply(replyData.commentId)
          setReplyData(null)
        }

        // 댓글일 경우
      } else {
        const res = await postComment({
          location: currentRoom?.url || window.location.href,
          comment: currentWritingComment
        });

        if (res?.status === 201) {
          setCurrentWritingComment("");
          handleRefreshComment(true);
          setCommentHeight(20);
          setCurrentOption('Recent')
          setIsMore(true)
          setReplyData(null)
        }
      }
    }
  }

  React.useEffect(() => {
    (async () => {
      console.log("통신시작")

      window.chrome.runtime.sendMessage(extension_id,
        {type: 'readAuthCodeLocal'},
        async (res) => {
          const authCode = res.data.authCode;
          if (authCode['auth_code']) {
            const token = authCode["auth_code"]
            syncHeader({
              key: 'Authorization',
              value: "Bearer " + token
            })
            const res = await getUserInfo();

            if (res.status === 200) {
              console.log("200 성공")
              setUser(res.data)
            }
          }
        },
      );

    })();
  }, [])

  React.useEffect(() => {
    (async () => {
      const roomId = searchParams.get("roomId") || "";
      setCurrentRoom({url: roomId})
    })();
  }, [])

  const [option, setOption] = useRecoilState(optionState)

  React.useEffect(() => {
    handleRefreshComment(true);
  }, [currentOption])

  React.useEffect(() => {
    handleRefreshComment(true);
  }, [currentRoom, user])

  const handleRefreshComment = async (isNew: boolean) => {
    setLoading(true)
    if (!currentRoom) return;
    if (isNew) offset.current = 0;
    if (currentOption === 'Recent') {
      const res = await postCommentUrl({
        currentUrl: currentRoom.url,
        limit,
        offset: offset.current,
        isUser: !!user
      })
      if (res.status === 200) {
        // res.data.reverse()
        if (isNew) setCurrentCommentList(res.data)
        else setCurrentCommentList(c => c.concat(res.data))
        offset.current += res.data.length
        if (res.data.length) setIsMore(false)
        setLoading(false)
      }
    }
    if (currentOption === 'Like') {
      const res = await postCommentBestUrl({
        currentUrl: currentRoom.url,
        limit,
        offset: offset.current,
        isUser: !!user
      })
      if (res.status === 200) {
        // res.data.reverse()
        if (isNew) setCurrentCommentList(res.data)
        else setCurrentCommentList(c => c.concat(res.data))
        offset.current += res.data.length
        if (res.data.length) setIsMore(false)
        setLoading(false)
      }
    }
  }

  const handleWritingComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCurrentWritingComment(e.target.value);
  }

  const handleCloseOption = (e: React.MouseEvent<HTMLDivElement>) => {
    setOption(null)
  }
  const handleReport = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setOption({
      open: true,
      type: "report",
      id: option?.id || "",
      userId: option?.userId || "",
      isReply: option?.isReply || false,
      firstCommentId: option?.firstCommentId || ""
    });
  }

  const handleClickReport = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    const res = await postReport({
      comment_uuid: option?.id || "",
      description: report,
    })
    if (res.status === 201) {
      // setReportSuccessModal(true)
      setOption({
        open: true,
        type: "reportSuccess",
        id: option?.id || "",
        userId: option?.userId || "",
        isReply: option?.isReply || false,
        firstCommentId: option?.firstCommentId || ""
      });
    }
  }

  const [report, setReport] = React.useState("");

  const handleChangeReportText = (e: any) => {
    setReport(e.target.value);
  }

  const handleBlockClick = (e: any) => {
    e.stopPropagation();
  }

  const handleMore = () => {
    handleRefreshComment(false);
  }

  const handleRemove = async (e: any, id: string) => {
    e.stopPropagation()

    if (option?.isReply) {
      const res = await deleteReply_id(id);
      if (res.status === 201) {
        setRefreshReply(option.firstCommentId)
        setOption(null);
      }
    } else {
      const res = await deleteComment_id(id);
      if (res.status === 201) {
        setOption(null);
        handleRefreshComment(true);
      }
    }
  }


  return (
    <Wrapper style={{height: '95vh'}}>
      {/*<Header>*/}
      {/*</Header>*/}
      <Body>
        {/* body */}
        {/* <Ads> */}
        {/* ads */}
        {/* </Ads> */}
        <Content>
          {/* content */}
          <ContentHeader>
            {/* content hedaer */}
            <Options>
              {/* options */}
              <Option selected={currentOption === "Like"} onClick={() => {
                setCurrentOption("Like");
                setIsMore(true)
              }}>인기순</Option>
              <Option selected={currentOption === "Recent"} onClick={() => {
                setCurrentOption("Recent");
                setIsMore(true)
              }}>최신순</Option>
            </Options>
            <RefreshWrapper onClick={() => handleRefreshComment(true)}>
              {/* refresh */}
              <Refresh width={20} height={20}/>
            </RefreshWrapper>
          </ContentHeader>
          <Comments>
            {/* comments */}
            {!loading && currentCommentList?.map(v => {
              return <Comment key={v.uuid} data={v} setInput={setCurrentWritingComment} setReplyData={setReplyData}/>
            })}
            {!loading && !currentCommentList || currentCommentList?.length === 0 && (

              <NoCommentMessage>
                지금 댓글을 작성하면<br/>
                인기 댓글이 되실 수 있습니다!
              </NoCommentMessage>
            )}
            {!loading && isMore &&
              <More onClick={handleMore}>더보기</More>
            }
            <LeftComment/>
          </Comments>
          {/* (opt.) comment hidden */}
          <InputWrapper>
            <CommentInput placeholder="댓글을 입력하세요." value={currentWritingComment}
                          onChange={handleWritingComment} onKeyPress={handleKeyDown}
                          onInput={handleCommentHeight} height={commentHeight} maxLength={300}/>
            <SendWrapper onClick={handleSendMessage}>
              <Send width={16} height={16}/>
            </SendWrapper>
          </InputWrapper>
        </Content>
      </Body>
      {!user &&
        <LoginWrapper>
          <LoginButton onClick={() => window.open(LOGIN_PATH)}>
            회원가입 또는 로그인
          </LoginButton>
        </LoginWrapper>
      }
      {user && option?.open &&
        <OptionWrapper onClick={handleCloseOption}>
          {option?.type === "default" &&
            <>
              <div style={{flex: 1}}/>
              {option.userId !== user.uuid &&
                <OptionButton onClick={handleReport}>
                  신고하기 🚨
                </OptionButton>
              }
              {option.userId === user.uuid &&
                <OptionButton onClick={(e) => {
                  handleRemove(e, option.id)

                }}>
                  삭제하기 ⚠️
                </OptionButton>
              }
            </>
          }
          {option?.type === "report" &&
            <>
              <div style={{flex: 1}}/>
              <ReportWrapper onClick={handleBlockClick}>
                <ReportLabel>신고 사유를 작성해주시길 바랍니다.</ReportLabel>
                <ReportText value={report} onChange={handleChangeReportText}
                            placeholder="10자 이상 입력해주세요."/>
                <ReportButtons>
                  <ReportCancelButton onClick={handleCloseOption}>취소</ReportCancelButton>
                  <ReportButton disabled={report.length < 10}
                                onClick={handleClickReport}>신고</ReportButton>
                </ReportButtons>
              </ReportWrapper>
            </>
          }
          {option?.type === "reportSuccess" &&
            <>
              <div style={{flex: 1}}/>
              <ReportSuccessModal>
                신고가 접수되었습니다.
              </ReportSuccessModal>
              <div style={{flex: 1}}/>
            </>
          }
        </OptionWrapper>
      }
    </Wrapper>
  )
}

export default LaunchTask;

const ReportSuccessModal = styled.div`
  background-color: #fff;
  padding: 14px 10px;
  border-radius: 20px;
  color: #fff;
  text-align: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 10px 0px 10px;
  background-color: white;
`;


const Body = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`;


const Ads = styled.div`
  width: 100%;
  height: 60px;
  border-radius: 4px;
  background-color: #2f3;
  margin-bottom: 10px;
`;

const Comments = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  margin-bottom: 4px;
  box-sizing: content-box;
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-end;
  background: white;
`;

const SendWrapper = styled.div`
  cursor: pointer;
`;

const CommentInput = styled.textarea<{ height: number }>`
  height: ${props => props.height}px;
  width: 100%;
  border-radius: 4px;
  outline: none;
  outline: 1px solid black;
  background-color: white;
  color: black;

  &:focus {
    outline: 2px solid black;
  }

  resize: none;
  overflow: hidden;
  min-height: 20px;
  max-height: 100px;
  padding: 0px;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
`;

const Options = styled.div`
  display: flex;
  gap: 10px;
`;

const Option = styled.div<{ selected: boolean }>`
  cursor: pointer;
  height: 20px;
  border-radius: 16px;
  background-color: ${props => props.selected ? 'rgb(0, 0, 75)' : 'rgb(223, 233, 253)'};
  color: ${props => props.selected ? "white" : "black"};
  padding: 4px 14px;
`;

const RefreshWrapper = styled.div`
  cursor: pointer;
`;


const LeftComment = styled.div`
  flex-shrink: 0;
  height: 8rem;
`;

const NoCommentMessage = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  height: 20%;
`;


const OptionWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0px;
  left: 0px;
`;

const OptionButton = styled.div`
  padding: 10px;
  font-size: 14px;
  color: #000;
  border-radius: 14px;
  background-color: #fff;
  text-align: center;
  cursor: pointer;
  margin-bottom: 10px;
  width: 90%;

`

const ReportWrapper = styled.div`
  background-color: #fff;
  padding: 14px 10px;
  border-radius: 20px;
`;


const ReportLabel = styled.div`
  color: #000;
  font-size: 16px;
`;

const ReportText = styled.textarea`
  min-height: 240px;
  width: calc(100% - 8px);
  padding: 4px;
  border-radius: 7px;
  background: rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  color: #000;
`;

const OptionClickArea = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 2;
`;

const ReportButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end
`;

const ReportButton = styled.div<{ disabled: boolean }>`
  color: red;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  padding: 3px 6px;
  ${props => props.disabled && css`
    color: gray;
    font-weight: 500;
  `}
`;

const ReportCancelButton = styled.div`
  color: #000;
  font-size: 14px;
  cursor: pointer;
  padding: 3px 6px;
`;

const LoginWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70%;
  border-radius: 10px;
  z-index: 10;
  // background-color: rgba(0, 0, 0, 0.3);
  background: linear-gradient(0deg, rgba(235, 235, 235, 1) 0%, rgba(235, 235, 235, 1) 50%, rgba(255, 255, 255, 0) 100%);
  border: 2px solid black;
  bottom: 2px;
  left: 2px;
  border: none;
  margin-bottom: 10px;
`

const LoginButton = styled.div`
  margin-top: 150px;
  font-size: 16px;
  color: #000;
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
`;

const More = styled.div`
  text-align: center;
  margin-top: 20px;
`;