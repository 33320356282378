import axios from "axios";

const server_url = process.env.REACT_APP_API_SERVER
const login_url = process.env.REACT_APP_LOGIN_PATH || ""

export const apiInstance = axios.create({
  baseURL: server_url,
})

export const syncHeader = ({ key, value }: { key: string; value: string }) => {
  apiInstance.defaults.headers.common[key] = value;
}

apiInstance.interceptors.response.use(
  (config) => config,
  (err) => {
    if (err.response?.status === 401) {
      window.location.assign(login_url)
    }
    return Promise.reject(err);
  }
);