import { apiInstance } from "../../api"


export const postRoomUrl = async ({ currentUrl }: { currentUrl: string }) => {
  const url = '/room/url';
  return apiInstance.post(url, { url: currentUrl })
  .then(res => res)
  .catch(err => err.response || err)
}

export const postCommentUrl = async ({ offset, limit, currentUrl, isUser }: { offset: number, limit: number, currentUrl: string, isUser: boolean }) => {
  const search = new URLSearchParams({
    offset: offset.toString(),
    limit: limit.toString(),
    is_user: isUser ? "true" : "false",
  });
  const url = '/room/comment?'  + search.toString();
  return apiInstance.post(url, { url: currentUrl })
  .then(res => res)
  .catch(err => err.response || err)
}

export const postCommentBestUrl = async ({ offset, limit, currentUrl, isUser }: { offset: number, limit: number, currentUrl: string, isUser: boolean  }) => {
  const search = new URLSearchParams({
    offset: offset.toString(),
    limit: limit.toString(),
    is_user: isUser ? "true" : "false",
  });
  const url = '/room/comment/best?' + search.toString();
  return apiInstance.post(url, { url: currentUrl })
  .then(res => res)
  .catch(err => err.response || err)
}