import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import './App.css';
import LoginFramework from './frameworks/LoginFramework';
import InfoPage from './page/InfoPage';
import InfoSuccessPage from './page/InfoSuccessPage';
import LoginInfoPage from './page/LoginInfoPage';
import LoginPage from './page/LoginPage';
import SuccessPage from './page/SuccessPage';
import LaunchTask from "./content/containers/LaunchTask";


const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";

function App() {
  return (

    <GoogleOAuthProvider clientId={clientId}>
      <RecoilRoot>
        <BrowserRouter>
          <Routes>
            <Route path="login">
              {/* login */}
              <Route path="" element={<LoginPage />}/>
              {/* user info */}
              <Route path="info" element={<LoginInfoPage/>}/>
              {/* login end */}
              <Route path="success" element={<SuccessPage />}/>
            </Route>
            <Route path="" element={<LoginFramework />}>
              <Route path="chat" element={<LaunchTask/>}/>
              <Route path="info" element={<InfoPage/>}/>
              <Route path="success" element={<InfoSuccessPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </RecoilRoot>
    </GoogleOAuthProvider>
  );
}

export default App;
